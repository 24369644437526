// import Moment from 'moment'
import Moment from 'moment-timezone'

export function formatUTCDate(date: string | undefined, format: string) {
  return Moment(date).subtract('7', 'hour').format(format)
}

export function formatAddUTCDate(date: string | undefined, format: string) {
  return Moment(date).add('7', 'hour').format(format)
}

export function formatDate(date: string | undefined, format: string) {
  return Moment(date).tz('Asia/Jakarta').format(format)
}

export function formatCurrencyShortForm(value) {
  if (!value) {
    return ''
  }
  value = Number(value)
  if (value >= 1000000000000) {
    return roundToDecimals(value / 1000000000000, 2) + ' Trillion'
  }

  return formatCurrency(value, 2)
}

export function formatCurrency(value: number, precision: number = 0) {
  if (!value) {
    return 0
  }

  if (precision === 0) {
    value = roundToDecimals(value, 0)
  }

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

function roundToDecimals(value: number, precision: number = 0) {
  const pow = Math.pow(10, precision)
  return Math.floor(value * pow) / pow
}
