import { lazy, FC, Suspense, Fragment } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'

const isAbleToRender = (arrayData: any = [], key: any) => arrayData?.includes(key);

const PrivateRoutes = () => {
  const { menus } = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const MarketingPage = lazy(() => import('../modules/apps/marketing/MarketingPage'))
  const KampusEksporPage = lazy(() => import('../modules/apps/courses/kampus-ekspor/KampusEksporPage'))
  const DataTradePage = lazy(() => import('../modules/apps/data-trade/DataTradePage'))
  const EventPage = lazy(() => import('../modules/apps/event/EventPage'))
  const NewsPage = lazy(() => import('../modules/apps/news/NewsPage'))
  const ArticlePage = lazy(() => import('../modules/apps/article/ArticlePage'))
  const ExporterPage = lazy(() => import('../modules/apps/exporter/ExporterPage'))
  const BBEPage = lazy(() => import('../modules/apps/bersama-bisa-ekspor/BBEPage'))
  const DataBuyerPage = lazy(() => import('../modules/apps/data-buyer/DataBuyerPage'))
  const CorporatePage = lazy(() => import('../modules/apps/corporate/CorporatePage'))
  const ModalEksporPage = lazy(() => import('../modules/apps/modal-ekspor/ModalEksporPage'))
  const MemberPage = lazy(() => import('../modules/apps/member/MemberPage'))
  const KTAPage = lazy(() => import('../modules/apps/kta/KTAPage'))
  const PaymentOrderPage = lazy(() => import('../modules/apps/order/OrderPage'))

  const getRouteGroupBasedPermission = (groupKey, subRoutesGroup) => {
    const routesGroup = {
      marketing: () => {
        const subMenus = subRoutesGroup['marketing']
        return <Route
          path='marketing/*'
          element={
            <SuspensedView>
              {(isAbleToRender(subMenus, 'pop-up-campaign') || isAbleToRender(subMenus, 'banner')) && <MarketingPage />}
            </SuspensedView>
          }
        />
      },
      courses: () => {
        const subMenus = subRoutesGroup['courses']
        return <Route
          path='courses/kampus-ekspor/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'kampus-ekspor') && <KampusEksporPage />}
            </SuspensedView>
          }
        />
      },
      operational: () => {
        const subMenus = subRoutesGroup['operational']
        return <Route
          path='operational/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'data-trade') && <DataTradePage />}
              {isAbleToRender(subMenus, 'event') && <EventPage />}
              {isAbleToRender(subMenus, 'news') && <NewsPage />}
              {isAbleToRender(subMenus, 'buyer-recommendation') && <DataBuyerPage />}
              {isAbleToRender(subMenus, 'user-management') && <MemberPage />}
              {isAbleToRender(subMenus, 'payment-order') && <PaymentOrderPage />}
            </SuspensedView>
          }
        />
      },
      'produk-ekspor': () => {
        const subMenus = subRoutesGroup['produk-ekspor']
        return <Route
          path='produk-ekspor/*'
          element={
            <SuspensedView>
              {isAbleToRender(subMenus, 'article') && <ArticlePage />}
            </SuspensedView>
          }
        />
      },
      'modal-ekspor': () => {
        const subMenus = subRoutesGroup['modal-ekspor']
        return <Route
          path='modal-ekspor/*'
          element={
            <SuspensedView>
              {(isAbleToRender(subMenus, 'dashboard') || isAbleToRender(subMenus, 'exporter')) && <ExporterPage />}
              {isAbleToRender(subMenus, 'submission') && <ModalEksporPage />}
              {isAbleToRender(subMenus, 'kta-status') && <KTAPage />}
            </SuspensedView>
          }
        />
      },
      'bersama-bisa-ekspor': () => {
        const subMenus = subRoutesGroup['bersama-bisa-ekspor']
        return <Route
          path='bersama-bisa-ekspor/*'
          element={
            <SuspensedView>
              {(isAbleToRender(subMenus, 'submission')) && <BBEPage />}
            </SuspensedView>
          }
        />
      },
      'legal': () => {
        const subMenus = subRoutesGroup['legal']
        return <Route
          path='legal/*'
          element={
            <SuspensedView>
              {(isAbleToRender(subMenus, 'corporate')) && <CorporatePage />}
            </SuspensedView>
          }
        />
      }
    }
    let routeToRender: any = [];
    groupKey?.forEach(key => {
      if (!routesGroup?.[key]) return;
      routeToRender?.push(routesGroup?.[key]);
    })
    return routeToRender;
  }

  const routesGroupArray = menus?.map(item => item?.group);
  let subRoutesGroupArray = {}
  menus?.forEach(item => {
    Object.assign(subRoutesGroupArray, {
      [item?.group]: item?.sub_menus?.map(sub => sub?.group)
    })
  })
  const routeList = getRouteGroupBasedPermission(routesGroupArray, subRoutesGroupArray)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {routeList?.map((item: any, index) => {
          return <Fragment key={index?.toString()}>
            {item()}
          </Fragment>
        })}
       
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
