import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
// models
import {
  ID,
  QueryResponseContextProps,
  GlobalQueryResponseContextProps,
  QueryState,
  GlobalQueryState,
} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createGlobalResponseContext<T>(initialState: GlobalQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID; data_trade_category_id?: ID; user_data_trade_id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(
    data
      .filter((item) => item.id || item?.data_trade_category_id || item?.user_data_trade_id)
      .map((item) => item.id || item?.data_trade_category_id || item?.user_data_trade_id)
  )
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

// New Function
function globalStringifyRequestQuery(state: GlobalQueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'limit'], skipNulls: true })
  const sort = qs.stringify(state, {
    filter: ['sort', 'order', 'data_trade_category_id'],
    skipNulls: true,
  })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''
  const searchTitle = qs.stringify(state, { filter: ['title'], skipNulls: true })
  const searchType = qs.stringify(state, { filter: ['type'], skipNulls: true })
  const statusType = qs.stringify(state, { filter: ['status'], skipNulls: true })

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const participantSort = qs.stringify(state, {
    filter: ['ticket_number', 'email', 'transaction_status', 'membership_status'],
    skipNulls: true,
  })

  return [pagination, sort, search, searchTitle, filter, participantSort, searchType, statusType]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function globalparseRequestQuery(query: string): GlobalQueryState {
  const cache: unknown = qs.parse(query)
  return cache as GlobalQueryState
}

export {
  createResponseContext,
  createGlobalResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  globalStringifyRequestQuery,
  globalparseRequestQuery,
}
