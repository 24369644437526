const QUERIES = {
  USERS_LIST: 'users-list',
  POPUP_LIST: 'popup-list',
  CATEGORY_LIST: 'category-list',
  TOPIC_LIST: 'topic-list',
  HIGHLIGHT_LIST: 'highlight-list',
  CHAPTER_LIST: 'chapter-list',
  BANNER_LIST: 'banner-list',
  DATA_TRADE_CATEGORY_LIST: 'data-trade-category-list',
  DATA_TRADE_LIST: 'data-trade-list',
  EVENT_LIST: 'event-list',
  PARTICIPANT_LIST: 'participant-list',
  PARTICIPANT_SUMMARY: 'participant-summary',
  NEWS_LIST: 'news-list',
  ARTICLE_LIST: 'article-list',
  EXPORTER_LIST: 'exporter-list',
  BBE_LIST: 'bbe-list',
  DATA_BUYER_LIST: 'data-buyer-list',
  CORPORATE_LIST: 'corporate-list',
  SUBMISSION_LIST: 'submission-list',
  MEMBER_LIST: 'member-list',
  KTA_LIST: 'kta-list',
  ORDER_LIST: 'order-list',
  WE_ORDER_LIST: 'we-order-list',
}

export {QUERIES}
