/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
// auth
import { useAuth } from '../../../../../app/modules/auth'

const getIconByGroup = (group) => {
  const defaultIcon = '/media/sidemenu/marketing002.svg'
  const icon = {
    marketing: '/media/sidemenu/marketing002.svg',
    courses: '/media/sidemenu/course001.svg',
    operational: '/media/sidemenu/ops001.svg',
    'produk-ekspor': '/media/sidemenu/produk001.svg',
    'modal-ekspor': '/media/sidemenu/modal001.svg',
    'bersama-bisa-ekspor': '/media/sidemenu/bbe001.svg',
    'legal':'/media/sidemenu/legal001.svg',
    'data-buyer': '/media/sidemenu/databuyer001.svg',
  }
  return icon?.[group] || defaultIcon
}

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { menus } = useAuth();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div>

      {menus?.map(item => {
        const icon = getIconByGroup(item?.group)
        return <SidebarMenuItemWithSub
          key={item?.id}
          to={item?.path}
          title={item?.label}
          icon={icon}
          fontIcon='bi-layers'
        >
          {item?.sub_menus?.map(submenu => {
            const isHasSubmenus = Boolean(submenu?.sub_menus?.length)
            if (isHasSubmenus) {
              return <SidebarMenuItemWithSub key={submenu?.id} to={submenu?.path} title={submenu?.label} hasBullet={true}>
                {submenu?.sub_menus?.map(obj => {
                  return <SidebarMenuItem key={obj?.id} to={obj?.path} title={obj?.label} hasBullet={true} />
                })}
              </SidebarMenuItemWithSub>
            }
            return <SidebarMenuItem key={submenu?.id} to={submenu?.path} title={submenu?.label} hasBullet={true} />
          })}
        </SidebarMenuItemWithSub>
      })}

      {/* LEGAL GROUP */}
      {/* END OF LEGAL GROUP */}

      {/* BBE GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/bersama-bisa-ekspor'
        title='Bersama Bisa Ekspor'
        icon='/media/sidemenu/legal001.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/bersama-bisa-ekspor/submission' title='Submission' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* END OF BBE GROUP */}

      {/* MARKETING GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/marketing'
        title='Marketing'
        icon='/media/sidemenu/marketing002.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/marketing/popup-campaign' title='Pop Up Campaign' hasBullet={true} />
        <SidebarMenuItem to='/marketing/banner' title='Banner' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* END OF MARKETING GROUP */}

      {/* COURSE GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/courses'
        title='Courses'
        fontIcon='bi-archive'
        icon='/media/sidemenu/course001.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Kampus Ekspor' hasBullet={true}>
          <SidebarMenuItem
            to='/courses/kampus-ekspor/category'
            title='Category'
            hasBullet={true}
          />
          <SidebarMenuItem to='/courses/kampus-ekspor/highlight' title='Highlight' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* END OF COURSE GROUP */}

      {/* OPERATIONAL GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/operational'
        title='Operational'
        icon='/media/sidemenu/ops001.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Data Trade' hasBullet={true}>
          <SidebarMenuItem
            to='/data-trade/category'
            title='Category'
            hasBullet={true}
          />
          <SidebarMenuItem to='/data-trade/list' title='List' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/event/list' title='Event' hasBullet={true} />
        <SidebarMenuItem to='/news/list' title='News' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* END OF OPERATIONAL GROUP */}

      {/* PRODUK EKSPOR GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/produk-ekspor'
        title='Produk Ekspor'
        icon='/media/sidemenu/produk001.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/produk-ekspor/article/list' title='Article' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* END OF PRODUK EKSPOR GROUP */}

      {/* EXPORTER GROUP */}
      {/* <SidebarMenuItemWithSub
        to='/exporter'
        title='Modal Ekspor'
        icon='/media/sidemenu/exporter001.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/exporter/dashboard' title='Dashboard' hasBullet={true} />
        <SidebarMenuItem to='/exporter/list' title='Exporter' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* END OF EXPORTER GROUP */}

      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
